import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";
import { Project } from "../../interfaces/Project";
import { ConfiguratorConfigResponse } from "../../components/rockwellConfigurator/interfaces/ConfiguratorConfigResponse";

class PstV1ApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "PST_V1_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    };
  }

  getLoadConfiguratorFromTemplate(templateId: string | null, libraryId: string | undefined, locale: string | undefined): Promise<any> {
    let queryParams = `?req=load&tid=${templateId}`;
    if (libraryId) {
      queryParams += `&cat=${libraryId}`;
    }
    if (locale) {
      queryParams += `&loc=${locale}`;
    }
    const url = `${getApiUrl(this.api)}/api/Config/${queryParams}`;
    return axios.get(url, this.config);
  }

  getToggleConfiguration(cid: string, eid: string) {
    let queryParams = `req=toggle&target=${eid}`;
    const url = `${getApiUrl(this.api)}/api/Config/${cid}?${queryParams}`;
    return axios.get<ConfiguratorConfigResponse>(url, this.config);
  }

  getSetAttributeValue(cid: string, id: string, value: string) {
    let queryParams = `req=setvalue&target=${id}&value=${value}`;
    const url = `${getApiUrl(this.api)}/api/Config/${cid}?${queryParams}`;
    return axios.get<ConfiguratorConfigResponse>(url, this.config);
  }

  setQuantity(cid: string, id: string, qty: string) {
    let queryParams = `req=setqty&target=${id}&value=${qty}`;
    const url = `${getApiUrl(this.api)}/api/Config/${cid}?${queryParams}`;
    return axios.get<ConfiguratorConfigResponse>(url, this.config);
  }

  getConfigurationTemplate(cid: string) {
    const url = `${getApiUrl(this.api)}/api/Config/${cid}`;
    return axios.get(url, this.config);
  }
}

const pstV1ApiService = new PstV1ApiService();
export default pstV1ApiService;
