import { AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Accordion from "@mui/material/Accordion";
import { ChevronDownIcon } from "@rockwell-automation-inc/ra-meatball";
import React, { useEffect, useState } from "react";
import { IGroup } from "../interfaces/IGroup";
import { IAttribute } from "../interfaces/IAttribute";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { truncate } from "fs";

interface GroupAccordionProps {
  group: IGroup;
  attributes: IAttribute[];
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  handleSetSelectedAttribute: Function;
  groupArray: Function;
  groupAttributeIndex: number;
  currentGroupIndex: number;
  currentAttribute: IAttribute | null;
  setCurrentAttribute: (attribute: IAttribute) => void;
  index: number;
}

const GroupAccordion = (props: GroupAccordionProps) => {
  const {
    group,
    attributes,
    configFromTemplateDataFromParent,
    handleSetSelectedAttribute,
    groupAttributeIndex,
    currentGroupIndex,
    index,
    groupArray,
    currentAttribute,
    setCurrentAttribute,
  } = props;
  const [configData, setConfigData] = useState<IConfiguratorFromTemplateResponse>();
  const [expanded, setExpanded] = useState<boolean>(true);

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  }, [configFromTemplateDataFromParent]);

  // useEffect(() => {
  //   setExpanded(currentGroupIndex === index ? group.displayText : false);
  // }, [currentGroupIndex, index, group.displayText]);

  useEffect(() => {
    if (currentGroupIndex === index && attributes) {
      setAttributeBorder(attributes[groupAttributeIndex]);
      handleSetSelectedAttribute(attributes[groupAttributeIndex], groupAttributeIndex, index);
    }
  }, [attributes, groupAttributeIndex]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(!expanded);
  };

  const hasAttributeSelected = (attribute: IAttribute) => {
    if (attribute.range && configData?.attributes) {
      const attributeRange = attribute.range.split(",");
      for (const range of attributeRange) {
        for (const configAttribute of configData.attributes) {
          if (range === configAttribute.id && configAttribute.isSelected) {
            return true;
          }
        }
      }
    }
    if ((attribute.class === "String" && attribute.value) || (attribute.class === "Real" && attribute.value)) {
      return true;
    }
    return false;
  };

  const handleAttributeClick = (attribute: IAttribute, groupAttributeIndex: number) => {
    setAttributeBorder(attribute);
    setCurrentAttribute(attribute);
    handleSetSelectedAttribute(attribute, groupAttributeIndex, index);
  };

  const setAttributeBorder = (attribute: IAttribute) => {
    const elements = document.querySelectorAll(".accordion_attribute");
    const elementsArray = Array.from(elements) as HTMLElement[];
    if (elementsArray && attribute) {
      elementsArray.forEach(element => {
        if (element.id === attribute.id) {
          element.style.border = "solid 2px #003E7E";
          element.style.borderRadius = "4px";
        } else {
          element.style.border = "";
          element.style.borderRadius = "";
        }
      });
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(group.displayText)}
      sx={{
        width: "100%",
      }}>
      <AccordionSummary
        sx={{ paddingLeft: "0px" }}
        expandIcon={
          <ChevronDownIcon
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
        }
        aria-controls={group.displayText + "-content"}
        id={group.displayText + "-header"}>
        <Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: "500", fontFamily: "Barlow !important" }}>{group.displayText}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {attributes?.map((attribute: IAttribute, index: any) => {
          return (
            <>
              {attribute.displayText !== "Custom Factory Installed Modification Request" && (
                <Box
                  onClick={() => handleAttributeClick(attribute, index)}
                  key={attribute.id}
                  id={attribute.id}
                  className="accordion_attribute"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}>
                  <Typography sx={{ fontFamily: "Barlow !important", fontWeight: "500", fontSize: "18px", lineHeight: "24px" }}>
                    {attribute.displayText}
                  </Typography>
                  {hasAttributeSelected(attribute) && <CheckCircleOutlineOutlinedIcon sx={{ width: "24px", height: "24px", color: "#12850A" }} />}
                  {!hasAttributeSelected(attribute) && <WarningAmberOutlinedIcon sx={{ width: "24px", height: "24px", color: "#FFCC00" }} />}
                </Box>
              )}
            </>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default GroupAccordion;
